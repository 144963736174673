.text {
    position: fixed;
    top: 40%;
    left: 5%;
    color: var(--white);
}

.text h1 {
    font-weight: 600;
}

.text h2 {
    font-weight: 400;
}

.logoDiv {
    position: absolute;
    bottom: 50px;
    right: 50px;
    left: 5%;
}

div.unetiqLogo {
    max-width: 200px;
    max-height: 70px;
    display: inline-block;
    margin-right: 5%;
}

.unetiqLogo img {
    width: 100%;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 1em;
}
