.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
:root {
    --primary: #0066ff;
    --secondary: #f4f4f4;
    --error: #960200;
    --black: #000000;
    --white: #ffffff;
    --grey: #a9a9a9;
    --darkGrey: #757575;
    --lightGrey: #f5f5f5;
    --lightGreen: #6dd386;
}

.ospBookingsTable .ag-header-group-text {
    white-space: pre-line !important;
}

.action-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.empty-row {
    background-color: lightgray !important;
}

.ag-cell:has(.plausibility-check-correct) {
    background-color: #71e25a !important;
}

.ag-cell:has(.plausibility-check-wrong) {
    background-color: #960200 !important;
    color: white;
}

.datepicker-circle-day {
    border: 1px solid red !important;
}

.ag-cell:has(.marked-for-release) {
    background-color: rgb(219, 219, 219);
}

.ag-cell:has(.ready-for-release) {
    background-color: rgb(255, 208, 0);
}

.ag-cell:has(.released) {
    background-color: rgb(177, 255, 177);
}

.ag-theme-material .ag-cell-inline-editing:has(.ag-rich-select) {
    min-width: 280px;
    min-height: 200px;
}

.ag-cell:has(.background-green) {
    background-color: rgb(177, 255, 177);
}

.ag-cell:has(.text-center) {
    text-align: center;
}
