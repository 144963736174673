@use '~ag-grid-community/styles' as ag;
@include ag.grid-styles(
    (
        theme: material,
        --ag-cell-horizontal-padding: calc(var(--ag-grid-size) / 2),
    )
);
.ag-theme-material {
    .grid-cell-centered {
        text-align: center;
    }
    div:has(.freigabe-cell) {
        justify-content: center;
    }
    .ag-center-cols-container {
        margin-right: 0 !important;
    }
    .ag-pinned-right-cols-container {
        margin-right: 0 !important;
    }
}
